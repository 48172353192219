import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  selectBookQueue,
  selectFilterLanguage,
  selectFilterLevel,
  selectFilterSource,
  selectFilterTopic,
  selectFilterType,
  selectFilterWords,
  selectFilters,
  selectSearch,
} from 'data/filter/selectors';
import { getFilteredBooks } from 'data/database';
import { actions as filterActions } from 'data/filter/slice';
import { InfiniteLoad } from 'components/organisms/CardList/InfiniteLoad';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { ThreeDots } from 'react-loader-spinner';
import { Wrapper, LoadingWrapper } from './wrappers';

export const CardList = () => {
  const dispatch = useDispatch();
  const bookQueue = useSelector(selectBookQueue);
  const filters = useSelector(selectFilters);
  const language = useSelector(selectFilterLanguage);
  const source = useSelector(selectFilterSource);
  const type = useSelector(selectFilterType);
  const level = useSelector(selectFilterLevel);
  const words = useSelector(selectFilterWords);
  const topic = useSelector(selectFilterTopic);
  const search = useSelector(selectSearch);
  const [index, setIndex] = useState(10);
  const [hasNextPage, setHasNextPage] = useState(true);
  const batchSize = 10;
  const isTablet = useMediaQuery('(max-width: 1370px)');
  const isMobile = useMediaQuery('(max-width: 768px)');

  // Reset the index to 0 whenever the filters change
  useEffect(() => {
    setIndex(10);
  }, [language, level, search, source, topic, type, words]);

  const getHeight = useCallback(() => {
    if (!isMobile && !isTablet) return window.innerHeight;
    return window.innerHeight + 8;
  }, [isMobile, isTablet]);
  const getWidth = useCallback(() => {
    if (isMobile) {
      return window.innerWidth - 16;
    }
    if (isTablet) {
      return window.innerWidth - 328;
    }
    return window.innerWidth - 415;
  }, [isTablet, isMobile]);

  const [bookPaneHeight, setBookPaneHeight] = useState(getHeight());
  const [bookPaneWidth, setBookPaneWidth] = useState(
    isMobile ? window.innerWidth : getWidth(),
  );

  window.addEventListener('resize', () => {
    setBookPaneHeight(getHeight());
    setBookPaneWidth(getWidth());
    return null;
  });

  useLayoutEffect(() => {
    setBookPaneHeight(getHeight());
    setBookPaneWidth(getWidth());
  }, [getHeight, getWidth]);

  async function loadNextPage() {
    // console.log('inside loadNextPage');
    const newBooks = await getFilteredBooks(filters, index, batchSize);

    // Update the bookQueue with the new books appended
    const updatedBooks = index === 0 ? newBooks : [...bookQueue, ...newBooks];
    dispatch(filterActions.setBookQueue(updatedBooks));

    if (newBooks) {
      // If this update was for the full batch size, then there should be another page available
      setHasNextPage(newBooks.length === batchSize);
      setIndex(index + batchSize);
    }
  }

  return (
    <Wrapper /* {...{ bookPaneWidth }} */>
      {bookQueue?.length ? (
        <InfiniteLoad
          {...{
            bookPaneHeight,
            bookPaneWidth,
            hasNextPage,
            isTablet,
            loadNextPage,
          }}
        />
      ) : (
        <LoadingWrapper>
          <ThreeDots width="100" color="#002664" ariaLabel="loading" visible />
        </LoadingWrapper>
      )}
    </Wrapper>
  );
};
