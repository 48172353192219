import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import themePrimary from 'shared/themePrimary';
import { Button } from 'components/atoms/Button';
import { Typography } from 'components/atoms/Typography';
import { Wrapper, TitleWrapper } from './wrappers';
import messages from './messages';

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `${themePrimary.color.blue500}80`,
    zIndex: 999,
  },
  content: {
    position: 'absolute',
    top: '25%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -25%)',
    border: `none`,
    background: 'none',
    outline: 'none',
    overflow: 'visible',
    padding: 0,
  },
};

export const BaseModal = ({
  children,
  height,
  hideCloseButton,
  isOpen,
  maxWidth,
  minHeight,
  onClose,
  onOpen,
  title,
  width,
  ...props
}) => {
  let newModalStyles = modalStyles;
  if (width) {
    newModalStyles = {
      ...modalStyles,
      content: {
        ...newModalStyles.content,
        width,
      },
    };
  }

  if (maxWidth) {
    newModalStyles = {
      ...modalStyles,
      content: {
        ...newModalStyles.content,
        maxWidth,
      },
    };
  }

  if (height) {
    newModalStyles = {
      ...modalStyles,
      content: {
        ...newModalStyles.content,
        height,
      },
    };
  }

  if (minHeight) {
    newModalStyles = {
      ...modalStyles,
      content: {
        ...newModalStyles.content,
        minHeight,
      },
    };
  }

  return (
    <Modal
      closeTimeoutMS={150}
      onAfterOpen={onOpen}
      onRequestClose={onClose}
      style={newModalStyles}
      {...{ isOpen }}
      {...props}
    >
      <Wrapper>
        <TitleWrapper>
          {!!title &&
            (typeof title === 'string' ? (
              <Typography bold label={title} />
            ) : (
              title
            ))}

          {!hideCloseButton && (
            <Button
              iconRight="close"
              label={<FormattedMessage {...messages.close} />}
              size="xs"
              variant="link"
              onClick={onClose}
            />
          )}
        </TitleWrapper>

        {children}
      </Wrapper>
    </Modal>
  );
};

BaseModal.defaultProps = {
  height: '',
  hideCloseButton: false,
  maxWidth: '',
  minHeight: '',
  onOpen: undefined,
  title: '',
  width: '',
};

BaseModal.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.string,
};
