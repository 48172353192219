import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px;
  background-color: ${p => p.theme.color.white100};
  box-shadow: ${p => p.theme.shadow.xxxl};

  @media ${p => p.theme.device.desktop} {
    border-radius: 24px;
  }

  /* react-modal Transitions */
  .ReactModal__Content & {
    opacity: 0.85;
    transform: translateY(16px);
    transition: all 150ms ease-in-out;
  }

  .ReactModal__Content--after-open & {
    opacity: 1;
    transform: translateY(0);
    width: 100vw;
    height: 100vh;

    @media ${p => p.theme.device.desktop} {
      width: 724px;
      height: auto;
    }
  }

  .ReactModal__Content--before-close & {
    opacity: 0.85;
    transform: translateY(16px);
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
`;
