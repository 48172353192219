import styled from 'styled-components';

export const ContentWrapper = styled.div`
  max-width: 720px;
  padding: 0px 44px;
  margin: 0 auto;

  > *:first-child {
    margin-bottom: 8px;
  }

  > *:not(:first-child):not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  margin: 28px 0;

  > button {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
`;
