import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'components/atoms/Typography';
import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import CheckItem from 'components/atoms/CheckItem';
import CaptionGraphic from 'components/atoms/CaptionGraphic';
import Carousel from 'components/molecules/Carousel';
import Row from 'containers/Row';
import Footer from 'containers/Footer';
import Background from 'images/hero-bg.png';
import Tablet from 'images/tablet-no-bg.png';
import Technology from 'images/technology.png';
import Thrive from 'images/thrive.jpg';
import Meet from 'images/meet.jpg';
import HeroIllustration from 'images/hero-illustration.png';
import TeamIllustration from 'images/team.png';
import Chantal from 'images/chantal-round.png';
import Elizabeth from 'images/elizabeth-round.png';
import Paul from 'images/paul-round.png';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { getPWADisplayMode } from 'shared/functions/utils';
import {
  images,
  booksMessages,
  worldMessages,
  haitiMessages,
  deviceMessages,
  tabletMessages,
  technologyMessages,
  thriveMessages,
  meetMessages,
} from './constants';

import {
  HeroWrapper,
  IllustrationWrapper,
  BackgroundWrapper,
  FilterWrapper,
  TitleWrapper,
  ContentWrapper,
  ChecksWrapper,
  ButtonsWrapper,
  RowWrapper,
  FoldWrapper,
  Tile,
  TilesWrapper,
  Wrapper,
  DonateWrapper,
  Divider,
  TeamWrapper,
  Team,
  TeamColumns,
} from './wrappers';
import messages from './messages';

const Home = () => {
  const [showInstall, setShowInstall] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const navigate = useNavigate();
  const display = getPWADisplayMode();
  const isTablet = useMediaQuery('(max-width: 1370px)');

  window.addEventListener('beforeinstallprompt', e => {
    e.preventDefault();
    setDeferredPrompt(e);
    setShowInstall(true);
  });

  const installClick = async e => {
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    await deferredPrompt.userChoice.then(() => {
      setShowInstall(false);
    });
  };

  /* window.addEventListener('appinstalled', () => {
    // Hide the app-provided install promotion
    setShowInstall(false);
    // Clear the deferredPrompt so it can be garbage collected
    setDeferredPrompt(null);
  }); */

  return (
    <Wrapper>
      <HeroWrapper>
        <IllustrationWrapper src={HeroIllustration} />
        <BackgroundWrapper style={{ backgroundImage: `url(${Background})` }} />
        <FilterWrapper />
        <TitleWrapper>
          <Typography
            variant="h1"
            as="h1"
            label={<FormattedMessage {...messages.header} />}
          />
        </TitleWrapper>
        <ContentWrapper>
          <Typography
            variant="text-lg"
            as="p"
            label={<FormattedMessage {...messages.pitch} />}
          />
          <ButtonsWrapper>
            <Button
              type="button"
              onClick={() => navigate('library')}
              label={<FormattedMessage {...messages.explore} />}
            />
            {display === 'browser' && showInstall && (
              <Button
                type="button"
                variant="ghost"
                onClick={installClick}
                label={<FormattedMessage {...messages.install} />}
              />
            )}
          </ButtonsWrapper>
        </ContentWrapper>
        <FoldWrapper>
          <Typography
            variant="h2"
            as="h2"
            label={<FormattedMessage {...messages.tileHeader} />}
          />
          <TilesWrapper>
            <Tile color="red500">
              <Icon variant="bookstack" />
              <Typography
                variant="h3"
                as="h3"
                label={<FormattedMessage {...messages.tileBooks} />}
              />
              <Divider opacity="0.2" />
              <ChecksWrapper>
                {booksMessages.map(msg => (
                  <CheckItem key={msg} icon="check-white" message={msg} />
                ))}
                <CheckItem key="resources" message="resources" />
              </ChecksWrapper>
            </Tile>
            <Tile color="blue500">
              <Icon variant="globe" />
              <Typography
                variant="h3"
                as="h3"
                label={<FormattedMessage {...messages.tileWorld} />}
              />
              <Divider opacity="0.2" />
              <CheckItem key="writtenRecorded" message="writtenRecorded" />
              <br />
              <ChecksWrapper columns>
                {worldMessages.map(msg => (
                  <CheckItem
                    columns
                    key={msg}
                    icon="check-white"
                    message={msg}
                  />
                ))}
              </ChecksWrapper>
            </Tile>
            <Tile color="blue200">
              <Icon variant="haiti" />
              <Typography
                variant="h3"
                as="h3"
                label={<FormattedMessage {...messages.tileHaiti} />}
              />
              <Divider opacity="0.2" />
              <ChecksWrapper>
                {haitiMessages.map(msg => (
                  <CheckItem key={msg} icon="check-red" message={msg} />
                ))}
              </ChecksWrapper>
            </Tile>
          </TilesWrapper>
        </FoldWrapper>
      </HeroWrapper>
      <DonateWrapper marginTop={40}>
        <Typography
          variant="h1"
          as="h1"
          label={<FormattedMessage {...messages.donateHeader} />}
        />
        <Button
          variant="primary"
          iconLeft="donate-white"
          onClick={() =>
            window.open(
              'https://www.paypal.com/donate/?hosted_button_id=KJUY73PTWCLWC',
              '_blank',
            )
          }
          label={<FormattedMessage {...messages.donate} />}
        />
      </DonateWrapper>
      <Carousel variant="small" {...{ images }} />
      <Row color="blue100" imageBg="blue200" imageRight={Tablet}>
        <Typography
          variant="h2"
          as="h2"
          label={<FormattedMessage {...messages.tabletHeader} />}
        />
        <CheckItem message="used" />
        <RowWrapper>
          {deviceMessages.map(msg => (
            <CheckItem key={msg} icon="check-red" message={msg} />
          ))}
        </RowWrapper>
        <br />
        <CheckItem message="downloading" />
        {tabletMessages.map(msg => (
          <CheckItem key={msg} icon="check-red" message={msg} />
        ))}
        <RowWrapper>
          <Button
            type="button"
            onClick={() => navigate('library')}
            label={<FormattedMessage {...messages.explore} />}
          />
          <Button
            type="button"
            variant="ghost"
            label={<FormattedMessage {...messages.userGuide} />}
            disabled
          />
        </RowWrapper>
      </Row>
      <Row color="white100" imageLeft={Technology} imagePadding={77}>
        <Typography
          variant="h2"
          as="h2"
          label={<FormattedMessage {...messages.technologyHeader} />}
        />
        <CheckItem message="noAccess" />
        {technologyMessages.map(msg => (
          <CheckItem key={msg} icon="check-red" message={msg} />
        ))}
        <CheckItem message="openSource" />
      </Row>
      <Row color="blue100" imageRight={Thrive}>
        <Typography
          variant="h2"
          as="h2"
          label={<FormattedMessage {...messages.thriveHeader} />}
        />
        <RowWrapper>
          {thriveMessages.slice(0, 3).map(item => (
            <CaptionGraphic
              key={item.msg}
              icon={item.icon}
              color={item.color}
              message={item.msg}
            />
          ))}
        </RowWrapper>
        <RowWrapper>
          {thriveMessages.slice(3, 6).map(item => (
            <CaptionGraphic
              key={item.msg}
              icon={item.icon}
              color={item.color}
              message={item.msg}
            />
          ))}
        </RowWrapper>
      </Row>
      <Row color="blue100" imageLeft={Meet}>
        <Typography
          variant="h2"
          as="h2"
          label={<FormattedMessage {...messages.meetHeader} />}
        />
        <CheckItem message="nonprofit" />
        {meetMessages.map(msg => (
          <CheckItem key={msg} icon="check-red" message={msg} />
        ))}
        <Button
          type="button"
          onClick={() => navigate('about-us')}
          label={<FormattedMessage {...messages.learn} />}
        />
      </Row>
      <TeamWrapper>
        <Team src={TeamIllustration} />
        <Typography
          variant="h1"
          as="h1"
          label={<FormattedMessage {...messages.teamHeader} />}
        />
        <TeamColumns>
          <div>
            <img alt="Chantal" src={Chantal} />
            <Typography variant="h3" as="h3" label="Chantal Coutard" />
            <Typography
              variant="text-md"
              as="p"
              label={<FormattedMessage {...messages.teamDirector} />}
            />
          </div>
          <div>
            <img alt="Elizabeth" src={Elizabeth} />
            <Typography variant="h3" as="h3" label="Elizabeth Wicks" />
            <Typography
              variant="text-md"
              as="p"
              label={<FormattedMessage {...messages.teamCofounder} />}
            />
          </div>
          <div>
            <img alt="Paul" src={Paul} />
            <Typography variant="h3" as="h3" label="Paul Wicks" />
            <Typography
              variant="text-md"
              as="p"
              label={<FormattedMessage {...messages.teamCofounder} />}
            />
          </div>
        </TeamColumns>
        <Button
          type="button"
          onClick={() => navigate('about-us')}
          label={<FormattedMessage {...messages.learn} />}
        />
      </TeamWrapper>
      <DonateWrapper marginTop={isTablet ? 60 : 120}>
        <Icon variant="donate-red" />
        <Typography
          variant="h1"
          as="h1"
          label={<FormattedMessage {...messages.futureHeader} />}
        />
        <Typography
          variant="text-md"
          as="p"
          label={<FormattedMessage {...messages.future} />}
        />

        <ButtonsWrapper>
          <Button
            variant="primary"
            iconLeft="donate-white"
            onClick={() =>
              window.open(
                'https://www.paypal.com/donate/?hosted_button_id=KJUY73PTWCLWC',
                '_blank',
              )
            }
            label={<FormattedMessage {...messages.donate} />}
          />
          {display === 'browser' && showInstall ? (
            <Button
              type="button"
              variant="primary"
              onClick={installClick}
              label={<FormattedMessage {...messages.install} />}
            />
          ) : (
            <Button
              type="button"
              onClick={() => navigate('library')}
              label={<FormattedMessage {...messages.explore} />}
            />
          )}
        </ButtonsWrapper>
      </DonateWrapper>

      <Footer />
    </Wrapper>
  );
};

export default Home;
