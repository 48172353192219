import styled from 'styled-components';

export const Wrapper = styled.nav`
  position: relative;
  background-color: ${p => p.theme.color.white100};
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;
  width: 100%;
  height: calc(100% - 56px);
  margin: 24px auto;
  overflow-y: auto;

  @media ${p => p.theme.device.desktop} {
    margin: 44px auto;
    height: calc(100% - 96px);
  }
`;

export const Header = styled.div`
  width: 100%;
  min-height: 50px;
  margin-bottom: 4px;

  @media ${p => p.theme.device.desktop} {
    margin-bottom: 24px;
    margin: 44px auto;
  }
`;

export const HeaderRow = styled.div`
  position: relative;
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.color.black100};

  * {
    padding: 2px;
    font-size: 12px;
  }

  > :not(:first-child) {
    position: absolute;
    right: -16px;
    top: -11px;
  }

  > :first-child :last-child {
    margin: 0px auto;
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;

  h3 {
    font-size: 18px;
    margin: 16px 0px;
  }
`;

export const Scroll = styled.div`
  width: 100%;
  height: calc(100% - 160px);
  overflow-y: scroll;

  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* hide scrollbar for chrome, safari and opera */
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Accordion = styled.div`
  border-top: 1px solid ${p => p.theme.color.black300};
  width: calc(100% - 8px);
  min-height: ${p => (p.open ? 'auto' : '50px')};
  margin-bottom: ${p => (p.open ? '16px' : '0px')};
  height: auto;
  transition: all 300ms ease;

  @media ${p => p.theme.device.desktop} {
    min-height: ${p => (p.open ? 'auto' : '80px')};
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${p => p.theme.color.red500};
  text-align: left;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  padding: 16px 0px;
  cursor: pointer;

  @media ${p => p.theme.device.desktop} {
    height: 80px;
    padding: 24px 0px;
  }
`;

export const Caret = styled.div`
  position: absolute;
  right: 0px;
  top: 14px;
  transform: ${p => !p.open && 'rotate(-90deg)'};
  transition: all 300ms ease;

  @media ${p => p.theme.device.desktop} {
    top: 28px;
  }
`;
