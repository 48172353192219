import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actions as filterActions } from 'data/filter/slice';
import {
  selectFilterLanguage,
  selectFilterType,
  selectFilterLevel,
  selectFilterWords,
  selectFilterTopic,
  selectFilterSource,
  selectSearch,
} from 'data/filter/selectors';

import { Button } from 'components/atoms/Button';
import { Icon } from 'components/atoms/Icon';
import { Typography } from 'components/atoms/Typography';
import { FilterOptions } from 'components/molecules/FilterOptions';
import { SearchBar } from 'components/molecules/SearchBar';
import {
  Wrapper,
  Header,
  Accordion,
  TitleWrapper,
  Scroll,
  Caret,
  Logo,
  HeaderRow,
} from './wrappers';
import messages from './messages';

export const FiltersBar = ({ isMobile, setIsMobileMenuOpen }) => {
  const [open, setOpen] = useState('');
  const dispatch = useDispatch();
  const language = useSelector(selectFilterLanguage);
  const source = useSelector(selectFilterSource);
  const type = useSelector(selectFilterType);
  const level = useSelector(selectFilterLevel);
  const words = useSelector(selectFilterWords);
  const topic = useSelector(selectFilterTopic);
  const search = useSelector(selectSearch);

  const handleAccordion = filter => {
    if (open === filter) {
      setOpen('');
    } else {
      setOpen(filter);
    }
  };

  const filterGroups = [
    { label: { single: 'language', multi: 'languages' }, value: language },
    { label: { single: 'source', multi: 'sources' }, value: source },
    { label: { single: 'level', multi: 'levels' }, value: level },
    { label: { single: 'type', multi: 'types' }, value: type },
    { label: { single: 'words', multi: 'lengths' }, value: words },
    { label: { single: 'topic', multi: 'topics' }, value: topic },
  ];

  return (
    <Wrapper>
      {isMobile && (
        <Button
          style={{ position: 'absolute', top: 10, right: 10 }}
          onClick={() => setIsMobileMenuOpen(false)}
          variant="icon-lg"
          iconLeft="close"
        />
      )}
      <Header>
        <Logo>
          <Link to="/">
            <Button variant="icon-lg" iconLeft="reading-room" label="" />
          </Link>
          <Typography
            variant="h3"
            as="h3"
            label={<FormattedMessage {...messages.navHeader} />}
          />
        </Logo>
        <HeaderRow>
          {(language[0] ||
            source[0] ||
            type[0] ||
            level[0] ||
            words ||
            words === 0 ||
            topic[0] ||
            search) && (
            <Button
              variant="text"
              size="sm"
              iconRight="close"
              label={<FormattedMessage {...messages.clearFilters} />}
              onClick={() => {
                dispatch(filterActions.clear());
                setOpen('');
              }}
            />
          )}
        </HeaderRow>
      </Header>
      <Scroll>
        <Accordion open={open === 'search'}>
          <TitleWrapper onClick={() => handleAccordion('search')}>
            <Typography
              variant="h4"
              as="h4"
              label={<FormattedMessage {...messages.search} />}
            />
            <Caret open={open === 'search'}>
              <Icon variant="down" />
            </Caret>
          </TitleWrapper>
          {open === 'search' && <SearchBar />}
        </Accordion>
        {filterGroups.map(group => (
          <Accordion
            key={group.label.single}
            open={open === group.label.single}
          >
            <TitleWrapper onClick={() => handleAccordion(group.label.single)}>
              {group.value?.length > 0 ||
              (group.label.single === 'words' &&
                (group.value || group.value === 0)) ? (
                <Typography
                  variant="h4"
                  as="h4"
                  label={
                    <FormattedMessage
                      {...messages[group.label.multi]}
                      values={{
                        number:
                          group.label.single === 'words'
                            ? 1
                            : group.value?.length,
                      }}
                    />
                  }
                />
              ) : (
                <Typography
                  variant="h4"
                  as="h4"
                  label={<FormattedMessage {...messages[group.label.single]} />}
                />
              )}
              <Caret open={open === group.label.single}>
                <Icon variant="down" />
              </Caret>
            </TitleWrapper>
            {open === group.label.single && (
              <FilterOptions filter={group.label.single} />
            )}
          </Accordion>
        ))}
      </Scroll>
    </Wrapper>
  );
};

FiltersBar.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  setIsMobileMenuOpen: PropTypes.func.isRequired,
};
