import React, { useEffect, useState } from 'react';
import { storeData, getLibraryCount, getFilteredBooks } from 'data/database';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Icon } from 'components/atoms/Icon';
import { Typography } from 'components/atoms/Typography';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions as filterActions } from 'data/filter/slice';
import { selectFilters } from 'data/filter/selectors';
import theme from 'shared/themePrimary';
import Nav from 'containers/Nav';
import Home from 'containers/Home';
import Library from 'containers/Library';
import Staff from 'containers/Staff';
import HelpOut from 'containers/HelpOut';
import { getPWADisplayMode } from 'shared/functions/utils';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { ScrollToTop } from './utils';
import { LoaderWrapper, OfflineMessage } from './wrappers';
import messages from './messages';

// TODO: have an endpoint tell us how many books are available instead of hardcoding endIndex
const endIndex = 900;

export const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const browser = getPWADisplayMode() === 'browser' && !isMobile;
  const showNav = browser && location.pathname !== '/library';
  const [progress, setProgress] = useState(0);
  const [index, setIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!index) {
          const startingDataLength = await getLibraryCount();
          const fetchIndex = Math.floor(startingDataLength / 10) * 10;
          // We want to round down to the nearest multiple of 10 to make sure we don't break the endpoint
          setIndex(fetchIndex > 10 ? fetchIndex : 10);
          return;
        }
        // If we already have all the data, no need to query for more
        if (index >= endIndex) {
          initializeBookQueue();
          setProgress(100);
        } else {
          // console.log('triggering fetch', index);
          const response = await fetch(
            `https://S420L.club/static/encoded/first${index}.json`,
          );
          if (!response.ok) throw new Error('Network response was not ok.');

          const data = await response.json();
          await storeData(data);
          setIndex(index + 10);
          const updatedProgress = (index / endIndex) * 100;
          // console.log(`book download progress: ${updatedProgress}%`);
          setProgress(updatedProgress);
          initializeBookQueue();
        }
      } catch (error) {
        console.error('Error fetching or processing data:', error);
      }
    };
      
    /*const initializeBookQueue = async () => {
      //const xhr = new XMLHttpRequest();
    //const requestBody = JSON.stringify({ range: [1, 24] });
    //xhr.open('POST', 'localhost:8000/api/get_books', true);
    //xhr.setRequestHeader('Content-Type', 'application/json');
    /*const bookQueue = await getFilteredBooks(filters, 0, 10);
      console.log("BOOK QUEUE!!");
      console.log("______________________________")
      console.log(bookQueue);
      console.log("______________________________")
     
      dispatch(filterActions.setBookQueue(bookQueue));
    };*/

    const initializeBookQueue = async () => {
      try {
        const response = await fetch('https://S420L.club/api/get_books', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({range: [1, 24]})
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
    
        const bookQueue = await response.json();
        console.log("BOOK QUEUE!!");
        console.log("______________________________")
        console.log(bookQueue);
        console.log("______________________________")
        dispatch(filterActions.setBookQueue(bookQueue));
      } catch (error) {
        console.error(error);
        // Handle errors here
      }
    };
    //if (index < endIndex) {
    //  fetchData();
    //} else {
      // console.log('finished fetching books');
      initializeBookQueue();
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, index]);

  return (
    <>
      <Helmet
        titleTemplate="%s - The Reading Room"
        defaultTitle="The Reading Room"
        htmlAttributes={{ lang: 'en-UK' }}
      >
        <meta name="description" content="The Reading Room application" />
      </Helmet>
      {showNav && <Nav />}
      <ScrollToTop />

      <LoaderWrapper>
        {progress < 100 && index < endIndex ? (
          <CircularProgressbar
            value={progress}
            styles={{
              root: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
              // Customize the path, i.e. the "completed progress"
              path: {
                // Path color
                stroke: theme.color.blue500,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Customize transition animation
                transition: 'stroke-dashoffset 0.5s ease 0s',
                // Rotate the path
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              // Customize the circle behind the path, i.e. the "total progress"
              trail: {
                // Trail color
                stroke: theme.color.red200,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Rotate the trail
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
            }}
          >
            Loading books...
          </CircularProgressbar>
        ) : (
          <OfflineMessage>
            <Icon variant="check-red" />
            <Typography
              variant="text-sm"
              as="p"
              label={<FormattedMessage {...messages.offline} />}
            />
          </OfflineMessage>
        )}
      </LoaderWrapper>
      <Routes>
        <Route index element={browser ? <Home /> : <Library />} />
        {browser && (
          <>
            <Route path="library" element={<Library />} />
            <Route path="about-us" element={<Staff />} />
            <Route path="how-to-help" element={<HelpOut />} />
          </>
        )}
      </Routes>
    </>
  );
};
