import styled from 'styled-components';

export const Wrapper = styled.section`
  color: ${p => p.theme.color.black100};
  background-color: ${p =>
    p.color ? p.theme.color[p.color] : p.theme.color.clear};
  display: flex;
  flex-direction: row;
  text-align: left;
  font-family: sans-serif;
  width: 100%;
  height: ${p => p.height || '640px'};

  @media ${p => p.theme.device.desktop} {
    height: ${p => p.height || '800px'};
  }
`;

export const ColumnWrapper = styled.div`
  padding: 40px;
  h2 {
    color: ${p => p.theme.color.blue500};
    font-weight: 600;
    margin-bottom: 44px;
  }
  p {
    color: ${p => p.theme.color.black200};
    margin-bottom: 16px;
  }
  button {
    margin-top: 28px;
  }

  @media ${p => p.theme.device.desktop} {
    padding: ${p => p.padding || '100px'};
  }
`;

export const ImageWrapper = styled.div`
  height: ${p =>
    p.imagePadding ? `calc(100% - ${p.imagePadding * 2}px)` : '100%'};
  width: ${p =>
    p.imagePadding ? `calc(50% - ${p.imagePadding * 2}px)` : '50%'};
  max-width: ${p => p.maxWidth};
  background-color: ${p => p.theme.color[p.imageBg]};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: ${p =>
    p.imagePadding ? `${p.imagePadding}px` : p.imageMargin ? '0px auto' : 0};
`;
